body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f2f2;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#chatWidget {
  position: fixed;
  right: 30px;
  bottom: 50px;
  z-index: 1;
}
.chatButton {
  border-radius: 50%;
  padding: 0;
  background: #2f2d2f;
  cursor: pointer;
  border: 0;
}
.chatButton img {
  display: block;
  height: 65px;
  width: 65px;
  padding: 15px;
}
.chatWrapper {
  display: flex; /* none */
  flex-direction: column;
  height: 600px; width: 400px; background-color: aliceblue;
  border-radius: 12px; overflow: hidden;
}
.chatHeader {
  background: #2f2d2f;
  height: 65px;
  display: flex;
  align-items: center;
  padding-left: 15px;
  flex-shrink: 0;
}
.chatHeader img {
  width: 30px;
  height: 30px;
}
.chatHeader .headline {
  color: white;
  margin: 0;
  padding-left: 15px;
}
.chatHeader .toggleIcon {
  color: white;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}
.chatMessages {
  display: flex;
  flex-direction: column;
  padding: 15px 15px 10px;
  overflow: auto;
}
/*.chatMessages::after {*/
/*    content: "";*/
/*    position: absolute;*/
/*    top: 80%;*/
/*    width: 30px;*/
/*    height: 20px;*/
/*    background-image: url("./assets/loading.gif");*/
/*    background-size: cover;*/
/*}*/
.assistent, .user {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  align-items: flex-end;
}
.user {
  justify-content: flex-end;
}
.assistent .avatar {
  width: 32px;
  height: 32px;
  padding-bottom: 10px;
}
.assistent .message {
  display: flex;
  flex-direction: column;
  margin-left: 7px;
}
.user .message {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.assistent .text {
  border-radius: 12px 12px 12px 0;
  background-color: #dde2eb;
  padding: 16px 20px;
  animation: pop .2s ease-out;
}
.user .text {
  border-radius: 12px 12px 0 12px;
  background-color: #2f2d2f;
  color: white; padding: 16px 20px;
  max-width: 291px;
  animation: pop .1s ease-out;
}
.assistent .time, .user .time {
  font-size: 10px;
  margin-top: 2px;
  color: #6c6c6c;
}
.user .time {
  align-self: flex-end;
}
.loadingMessage {
  margin-top: -33px;
}
.loadingMessageIndicator {
  width: 30px;
  margin-left: 15px;
}
.chatAction {
  display: flex;
  margin-top: auto;
}
.chatInput {
  width: 100%;
  border-top: 1px solid #d2d2d2;
  border-right: none;
  border-left: none;
  border-bottom: none;
  height: 50px;
  padding: 0 0 0 20px; /*reset*/
  outline: none;
}
.chatAction button {
  background-color: #2f2d2f; color: white;
  border: none; outline: none;
  position: absolute;
  right: 7px;
  bottom: 10px;
  cursor: pointer;
}
.chatAction button:hover {
  background-color: #2f2d2f; color: white;
}

@keyframes pop {
  0% {transform: scale(0)}

  80% {transform: scale(1.1);}

  100% {transform: scale(1);}
}
.code {
  background-color: #ebebeb;
  padding: 0 3px;
  border: 1px solid #dfdfdf;
  border-radius: 4px;
  font-size: 12px;
}