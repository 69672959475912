/*TODO: Implement SCSS compile*/

/*html {*/
/*    background-color: #343434;*/
/*    color: white;*/
/*}*/

html * {
    box-sizing: border-box;
}

.app {
    /*color: white;
    background-color: #282c34;*/
    margin: 0;
    min-height: 100vh;
    padding: 50px;
}

a {
    color: #61dafb;
}

nav span {
    padding: 10px 20px 0 0;
}

.wrapper {
    margin-top: 60px;
}

.inputUpload {
    margin-right: 30px;
    cursor: pointer;
}

.no-data-msg {
    margin: 60px 0 100px;
}

.modal-inner {
    min-height: 400px;
    display: flex;
    flex-wrap: wrap;
}

.modal-inner-content {
    width: 100%;
}

.modal-inner-action-buttons {
    margin-top: auto;
    width: 100%;
    padding-top: 40px;
}

/*.MuiNativeSelect-root, .MuiNativeSelect-select, .MuiInputBase-root, .MuiNativeSelect-select {*/
/*    height: 100% !important;*/
/*    max-height: 400px !important;*/
/*    width: 100% !important;*/
/*    max-width: 800px!important;*/
/*}*/

.upload {
    border: #1976d2 2px dashed;
    border-radius: 5px;
}

.upload-area {
    display: flex;
    justify-content: center;
    text-align: center;
    position: relative;
    padding: 150px;
}

.upload-area p{
    font-size: 22px;
    margin: 0;
}

.upload-area span {
    color: grey;
}

/* File Upload */
.form-file-upload {
    height: 200px;
    width: 100%;
    max-width: 100%;
    text-align: center;
    position: relative;
}

.form-file-upload.disabled {
    pointer-events: none;
}

.form-file-upload.disabled::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.form-file-upload.hide {
    display: none;
}

.input-file-upload {
    display: none;
}

.label-file-upload {
    cursor: pointer;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1px;
    border-style: dashed;
    border-color: #0072EF;
}

#drag-file-element {
    background-color: rgba(0, 124, 211, 0.3);
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .3;
}

.table {
    position: relative;
    background-color: white;
    * {
        font-size: 14px;
    }
}

.table.disabled {
    pointer-events: none;
}

.table.disabled::after {
    content: "";
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.8);
}

.table.dragActive::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .3;
    background-color: rgba(0, 124, 211, 0.3);
}

.color-picker-el {
    position: absolute;
    margin-top: 1px;
    z-index: 2;
}

.color-picker::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .7;
    z-index: 1;
}

.action-footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 64px;
    align-items: center;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.24) 0 -2px 5px;
    z-index: 1;
    padding: 0 30px 0 50px;
}