.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 350px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.modal-title {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.modal-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
}

.modal-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
        background-color: #0056b3;
    }
}

.modal-checkbox {
    margin-top: 20px;
}

.error {
    color: orangered;
    margin-top: 20px;
}

.ReactModal__Content, .ReactModal__Overlay {
    background-color: #e8e8e8 !important;
    z-index: 999;
}
