/*STEP 3 STYLING*/
.editDiv {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #9B9B9B;
    margin-left: 60%;
}

.borderDiv {
    border: 1px solid #DCDCDC;
    border-radius: 5px;
    padding-left: 15px;
}

.editText {
    padding-right: 10px;
    padding-left: 5px;
    margin: 0;
}